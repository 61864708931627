.Tags_TitleBar {
  background: #f5f8f9;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.01em;
  margin: 0;
  padding: 12px;
}

.Tags_Title {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.Tags_Title_Marker {
  margin-left: 8px;
  margin-right: 6px;
}

.Tags_Cont {
  margin: 0 auto;
  padding: 16px;
  max-width: 980px;
}

.Tags_Form {
  width: 100%;
  max-width: 100%;
  display: flex;
}

.Tags_Form .Tags_Input {
  flex: 1;
}

.Tags_ResultsTitle {
  width: 100%;
  display: flex;
  font-size: 20px;
  line-height: 1.1;
  color: #172b4d;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 900;
}

.Tags_Number {
  font-variant-numeric: tabular-nums;
}

.Tags_ResultsRowEnd {
  margin-top: -6px;
  margin-left: auto;
}

.LoginForm_Cont {
  display: flex;
  height: calc(100vh - 50px);
}

.LoginForm {
  margin: auto;
  min-height: 160px;
  padding: 24px 16px;
  border-radius: 3px;
}

.LoginForm_Logo {
  color: #172b4d;
  margin: 16px auto;
  text-align: center;
}

.TrackCard {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 620px;
  padding: 10px 0;
  margin: 10px;
  background: #f5f8f9;
  padding: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.TrackCard_TagRow {
  display: flex;
  margin-top: 4px;
  font-size: 14px;
}

.TrackCard_TagName {
  min-width: 100px;
  text-align: right;
  margin-right: 12px;
  color: #6b778c;
}

.TrackCard_TagValues {
  min-width: 0;
  word-break: break-word;
}

.TrackCard_Close {
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  padding: 12px;
  cursor: pointer;
  font-size: 24px;
  color: #6b778c;
}

.TrackCard_Close:hover {
  opacity: 0.5;
}

.TrackCard_Title {
  margin-top: 8px;
  margin-bottom: 16px;
  color: #42526e;
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 40px;
}

.TrackCard_Link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TrackCard_Spacer {
  min-height: 16px;
}

.TrackCard_Link svg {
  vertical-align: bottom;
  margin-right: 2px;
}

.TrackCard_Link a {
  color: inherit;
}

.TrackCard_Footer {
  padding-top: 16px;
  margin-top: auto;
  text-align: right;
}

.TrackCard_Dump {
  overflow-x: scroll;
  font-size: 12px;
}

.Tags_ResultsGrid {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
  grid-gap: 32px;
}
