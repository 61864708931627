.Tags_Button {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  cursor: pointer;

  border-radius: 3px;
  border-color: transparent;
  border-style: solid;
  border-width: 2px;
  padding: 0 16px;

  margin-left: 10px;

  min-width: 40px;
  min-height: 40px;
}

.Tags_Button[data-appearance='subtle'] {
  background: #091e420a;
}

.Tags_Button[data-appearance='subtle']:hover {
  background: #f4f6f8;
}

.Tags_Button[data-appearance='subtle'][disabled] {
  cursor: default;
}

.Tags_Button[data-appearance='primary'] {
  background: #36b37e;
  color: #fff;
}

.Tags_Button[data-appearance='primary']:hover {
  background: #57d9a3;
}

.Tags_Button[data-appearance='primary'][disabled] {
  color: #a5adba;
  background: #f4f5f7;
  cursor: default;
}

.Tags_Button a {
  text-decoration: none;
  color: inherit
}
