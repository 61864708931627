.Tags_Label {
  display: block;
  color: #6b778c;
  user-select: none;
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 8px;
}

.Tags_Label[data-alert] {
  color: #fc644d;
}

.Tags_Label a {
  color: #357cb4;
}
