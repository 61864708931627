.Tags_Input {
  background: #f4f5f7;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px #d7d7d7;
  color: #7a869a;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 8px;
}

.Tags_Input::placeholder {
  color: currentcolor;
  opacity: 0.7;
}

.Tags_Input:focus {
  background: #fff;
  box-shadow: inset 0 0 0 2px #94a7af;
  color: #212b36;
  outline: none;
}


.Tags_Input[data-error] {
  background: #fbeae5;
  box-shadow: inset 0 0 0 2px #fc644d;
}
