/* CircularStd @font-face kit */
@font-face {
  font-family: 'CircularStd';
  src: url('./CircularStd-Bold.eot');
  src: url('./CircularStd-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./CircularStd-Bold.woff') format('woff'),
    url('./CircularStd-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'CircularStd';
  src: url('./CircularStd-Medium.eot');
  src: url('./CircularStd-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('./CircularStd-Medium.woff') format('woff'),
    url('./CircularStd-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'CircularStd';
  src: url('./CircularStd-Book.eot');
  src: url('./CircularStd-Book.eot?#iefix')
      format('embedded-opentype'),
    url('./CircularStd-Book.woff') format('woff'),
    url('./CircularStd-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
